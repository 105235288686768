.testimonial-container {
    margin: 15px 150px;
    padding-bottom: 0;
    text-align: center;
}

.team {
    flex-direction: column;
}

.team__desc {
    text-align: center;
    font-size: 1rem;
    max-width: 600px;
}

.testimonials-description {
    color: #555;
    text-align: center;
    margin: 10px 0;
    padding: 0 30px;
}


.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    display: none !important;
}

li.alice-carousel__dots-item.__active {
    background-color: var(--clr-primary) !important;
}

ul.alice-carousel__dots {
    padding: 8px;
    margin: 0;
}

@media (max-width: 1100px) {
    .testimonial-container {
        margin: 15px 30px;
    }
}

@media (max-width: 800px) {
    .testimonial-container {
        margin: 15px 25px;
    }
}

@media (max-width: 600px) {
    .team__desc {
        text-align: center;
        margin-top: 2em;
    }
}