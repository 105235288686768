.event {
    flex-direction: column;
    margin-top: 3em;
}

.event__role {
    margin-top: 1.2em;
    @media (max-width: 600px) {
        margin: auto;
        text-align: center;
    }
}

.event__desc {
    text-align: center;
    font-size: 1rem;
    max-width: 600px;
}

.event__desc,
.event__contact {
    margin-top: 2.4em;
    margin-right: auto;
    margin-left: auto;
}


@media (max-width: 600px) {
    .event__desc {
        margin-top: 2em;
    }
}

@media (max-width: 600px) {
    .app .event {
        align-items: flex-start;
        margin-top: 2em;
    }
}

.title {
    padding: 3em 0;
    margin-top: 4em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.event__title {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--clr-fg);
}

@media (max-width: 600px) {
    .title {
        padding: 2em;
        margin-top: 3em;

    }
}
