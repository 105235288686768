.faq {
    flex-direction: column;
}

.faq__title {
    text-align: center;
    margin-bottom: 1em;
    text-transform: uppercase;
}

.faq__desc {
    text-align: center;
    font-size: 1rem;
    max-width: 600px;
}

.faq__contact {
    margin-top: 2.4em;
}

@media (max-width: 600px) {
    .faq__desc {
        text-align: center;
        margin-top: 2em;
    }
}
