.success {
    flex-direction: column;
    margin-top: 3em;
}

.success__desc {
    font-size: 1rem;
    max-width: 600px;
}

.success__title {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--clr-fg);
}

.title {
    padding: 3em 0;
    margin-top: 4em;
    text-align: center;
}

.success__nb {
    color: var(--clr-primary);
}

.success__name {
    margin-top: 10px !important;
}


.image-container {
    width: 100%;
    height: 25rem;
    overflow: hidden;
    border-radius: 9rem 20rem;
}
.image-container > img {
    width: 100%;
    height: 100%;
}

.image-container:hover {
    transition: width 0.2s ease-in;
    border: 3px solid var(--clr-primary);
}

@media (max-width: 600px) {
    .image-container {
        height: 18rem;
    }
    .success__name {
        margin-top: 5px !important;
    }
    .mobile_grid{
        padding: 25px !important;
    }
}
