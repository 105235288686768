.nav__list {
    margin-right: 1.5em;
    display: flex;
}
.nav__list-item {
    margin-left: 1.5em;
}

.app .nav__hamburger {
    display: none;
}

.nav__theme {
    margin-top: 0.4em;
}

@media (max-width: 600px) {
    .nav__list {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .nav__list-item {
        margin: 0.5em 0;
    }

    .app .nav__hamburger {
        display: flex;
        z-index: 2;
        margin-left: 0.8em;
    }
}
