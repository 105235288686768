.header {
    height: 8em;
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
}

.header_logo{
    margin-right: auto;
    width: 155px;
    height: 55px;
}

@media (max-width: 600px) {
    .header {
        height: 6em;
    }
    .header_logo{
        margin-right: 15px;
        margin-left: 5px;
    }
}

@media (max-width: 400px) {
    .header {
        height: 6em;
    }
    .header_logo{
        margin-right: 0px;
        margin-left: -10px;
    }
}
